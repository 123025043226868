import { useEffect, useState } from "react";

const IsMobile = (size = 768) => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
  const [isMobile, setIsMobile] = useState(windowSize.width <= size);

  function handleResize() {
    setWindowSize({ width: window?.innerWidth, height: window?.innerHeight });
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsMobile(windowSize.width <= size);
  }, [windowSize.width]);

  return isMobile;
};

export default IsMobile;
