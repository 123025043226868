import React, { useEffect, useState } from "react";
import SliderComponents from "../components/HomeSlider/Slider";

import { NextIcon, PlayIcon, StarIcon } from "../utils/icons";
import BehindSwifty from "../components/BehindSwifty/BehindSwifty";
import Button from "../components/Button/Button.component";
import LatestTweets from "../components/LatestTweets/LatestTweets";
import axios from "axios";
import LatestNewsV2 from "../components/LatestNews/LatestNewsV2";
import IsMobile from "../utils/isMobile";

import HomeSlider from "../components/HomeSlider/HomeSlider";
import { Link, useNavigate } from "react-router-dom";
import VideoBackground from "../components/VideoBackground/VideoBackground";

import "../components/Styles/HomeV2.css";

const HomeV2 = () => {
  const isMobile = IsMobile();
  const isTablet = IsMobile(991);
  const navigate = useNavigate();

  const [cards, setCards] = useState([]);
  const [playVideo, setPlayVideo] = useState(false);

  const weAreSwifty = isMobile
    ? "../images/we-are-swifty.png"
    : "../images/we-are-swifty-desktop.png";

  const images = [
    "../images/swifty-technologies.png",
    "../images/swifty-sports.png",
    "../images/swifty-predictions.png",
  ];
  const imagesMobile = [
    "../images/swifty-technologies-mobile.png",
    "../images/swifty-sports-mobile.png",
    "../images/swifty-predictions-mobile.png",
  ];
  const imagesTablet = [
    "../images/swifty-technologies-tablet.png",
    "../images/swifty-sports-tablet.png",
    "../images/swifty-predictions-tablet.png",
  ];

  const getDocs = () => {
    axios
      .post(
        "https://18z0qwmdu8.execute-api.eu-west-1.amazonaws.com/main/v1/data",
        {
          collection: "articles",
          sort: { date: -1 },
          limit: 3,
        }
      )
      .then((res) => {
        setCards(res.data.documents);
      });
  };
  useEffect(() => {
    getDocs();
  }, []);

  const goToNews = (slug) => {
    navigate(`/article/${slug}`);
  };

  const getDate = (date) => {
    return new Date(date).toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <>
      <div className="home-v2">
        <div className="container">
          <div className="home-header">
            <h2 className="text-uppercase">
              Experience the thrill of the game and the excitement of winning
            </h2>
            <div className="home-subheader">
              <p>
                Dive into the thrill of sports betting with our extensive range
                of options. Whether you're a fan of horse racing, football,
                rugby, or any other sport, we've got you covered.
              </p>
            </div>
          </div>
        </div>

        <SliderComponents
          images={isMobile ? imagesMobile : isTablet ? imagesTablet : images}
        />
      </div>

      <div className="home-banner-text container">
        {isMobile ? (
          <>
            <span>
              <StarIcon /> <p>Bet on 1,000+ Daily Events</p> <StarIcon />
            </span>
            <span>
              <StarIcon /> <p>Over 2,000+ Casino Games</p>
              <StarIcon />
            </span>
            <span>
              <StarIcon /> <p>Licensed in 4 Jurisidctions</p> <StarIcon />
            </span>
          </>
        ) : (
          <>
            <p>Bet on 1,000+ Daily Events</p>
            <StarIcon />
            <p>Over 2,000+ Casino Games</p>
            <StarIcon />
            <p>Licensed in 4 Jurisidctions</p>
          </>
        )}
      </div>

      <BehindSwifty />
      {/* <LatestTweets /> */}

      <div className="watch-video">
        <img src={weAreSwifty} alt="we-are-swifty" />
        <PlayIcon onClick={() => setPlayVideo(true)} />

        {playVideo && (
          <VideoBackground
            videoSrc={`https://cdn-swifty-content.s3.eu-west-1.amazonaws.com/webiste_swifty_global/media/WE_ARE_SWIFTY.mp4`}
            isVideoOpen={playVideo}
            setIsVideoOpen={setPlayVideo}
          />
        )}
      </div>

      {isTablet ? (
        <div className="latest-news-v2 container">
          <div className="latest-news-header">
            <p className="m-0">Latest News</p>

            <Button
              text="View All"
              className="general-button view-all-button"
              onclickHandler={() => navigate("/whatsnew")}
            />
          </div>
          <HomeSlider
            children={cards.map((row, index) => {
              const { image, title, date, slug } = row;
              return (
                <div key={index} className={`home-slider-item`}>
                  {image.length > 0 ? (
                    <img
                      src={image}
                      className="image"
                      alt={title}
                      onClick={() => goToNews(slug)}
                    />
                  ) : (
                    <img
                      src="images/swifty-placeholder.png"
                      className="image"
                      alt="Swifty Placeholder Image"
                      onClick={() => goToNews(slug)}
                    />
                  )}

                  <div className="content">
                    <p className="date">{getDate(date)}</p>
                    <span onClick={() => goToNews(slug)}>
                      <p className="title">{title}</p>

                      <Link to={`/article/${slug}`} className="">
                        <NextIcon fill="#4086FA" />
                      </Link>
                    </span>
                  </div>
                </div>
              );
            })}
          />
        </div>
      ) : (
        <LatestNewsV2 page={"home"} types={"all"} latestNews={cards} />
      )}
    </>
  );
};

export default HomeV2;
