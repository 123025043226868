import * as React from "react";

import "./Input.css";

const Input = (props) => {
  const inputContainer = props.inputContainer || "";
  const inputBody = props.inputBody || "";
  const inputTitle = props.inputTitle || "";
  const label = props.label || "";
  const value = props.value || "";
  const placeholder = props.placeholder || label;

  const onChange = props.onChange || null;

  return (
    <div className={`input-container ${inputContainer}`}>
      {label && <span className={`input-title ${inputTitle}`}>{label}</span>}

      <input
        onChange={(e) => onChange(e)}
        type="text"
        value={value}
        className={`input-body ${inputBody}`}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Input;
