import React, { useEffect, useState } from "react";

import axios from "axios";
import { useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import Moment from "moment";

import { BackIcon } from "../utils/icons";
import { useNavigate } from "react-router-dom";

import "../components/Styles/BlogPageV2.css";
import { Skeleton } from "../components/Skeleton/Skeleton";

const BlogPageV2 = () => {
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let { article: articleSlug } = useParams();

  const handleGoBack = () => {
    navigate(-1);
  };

  const getArticle = () => {
    setIsLoading(true);
    axios
      .post(
        "https://18z0qwmdu8.execute-api.eu-west-1.amazonaws.com/main/v1/data",
        {
          collection: "articles",
          filter: { slug: articleSlug },
          limit: 1,
        }
      )
      .then((res) => {
        setArticle(res.data.documents[0]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getArticle();
  }, [articleSlug]);

  return (
    <div className="blog-page-v2">
      <div className="container">
        {isLoading ? (
          <>
            <Skeleton sx={{ marginBottom: "10px" }} height={20} />
            <Skeleton sx={{ marginBottom: "10px" }} height={70} />
            <Skeleton sx={{ marginBottom: "10px" }} height={100} />
            <Skeleton sx={{ marginBottom: "10px" }} height={200} />
          </>
        ) : (
          <>
            <div className="back-icon" onClick={handleGoBack}>
              <BackIcon fill="#232A38" />
              <p>Back</p>
            </div>
            {article ? (
              <div className="articleContent">
                <div className="article-date">
                  {Moment(article.date).format("dddd, DD MMMM YYYY")}
                </div>
                <div className="article-title">{article.title}</div>
                <div className="article-description short-description">
                  {article.short_description}
                </div>
                {article.video.length <= 0 && article.image.length > 0 ? (
                  <div className="article-image">
                    <img
                      src={article.image}
                      className="card-img-top"
                      alt={article.title}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="article-description">
                  {HTMLReactParser(article.description)}
                </div>
              </div>
            ) : article !== null ? (
              <div className="articleContent">
                <div className="article-title">
                  This article could not be found, if you entered the url please
                  check it again.
                </div>
                <div className="article-title">
                  Our latest articles can be found below or you can go back to
                  our latest news page by &nbsp;
                  <a href="/whatsnew">Clicking Here</a>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default BlogPageV2;
