import React, { useEffect, useState } from "react";
import { SwiftyIcon } from "../../utils/icons";
import { useNavigate } from "react-router-dom";
import IsMobile from "../../utils/isMobile";

import "./HeaderV2.css";

const HeaderV2 = () => {
  const isMobile = IsMobile();
  const isTablet = IsMobile(991);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const links = [
    { link: "/", name: "HOME" },
    { link: "/whoweare", name: "WHO WE ARE" },
    { link: "/whatsnew", name: "WHAT’S NEWS" },
    { link: "/media", name: "MEDIA" },
    { link: "/contact", name: "CONTACT US" },
  ];

  const handleToggle = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    if (showMenu) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "";

    return () => {
      document.body.style.overflow = "";
    };
  }, [showMenu]);

  useEffect(() => {
    if (!isMobile) setShowMenu(false);
  }, [isMobile]);

  return (
    <div className={`header ${showMenu ? "header-mobile" : ""}`}>
      <div className={`${!isTablet && "container"} header-container`}>
        <SwiftyIcon onClick={() => navigate("/")} />

        <button
          className={`header-toggler d-lg-none d-flex ${
            showMenu ? "" : "collapsed"
          }`}
          aria-expanded={showMenu}
          onClick={handleToggle}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <div
          className={`header-items ${
            showMenu ? "header-items-mobile open" : ""
          }`}
        >
          {links.map((row, i) => {
            const { link, name } = row;
            return (
              <div
                key={i}
                className={`item ${
                  window.location.pathname === link ? "header-active" : ""
                }`}
              >
                <a href={link}>{name}</a>
                <span></span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HeaderV2;
