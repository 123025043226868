import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import CookieComponent from "../components/CookieComponent/CookieComponent";
import Header from "../components/Header/Header";
import "../components/Styles/CookieTermsPrivacy.css";

const CookiePolicy = () => {
  useEffect(() => {
    (function (d, s, id) {
      var js,
        tjs = d.getElementsByTagName(s)[0];
      // if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://app.termly.io/embed-policy.min.js";
      tjs.parentNode.insertBefore(js, tjs);
    })(document, "script", "termly-jssdk");
  }, []);
  return (
    <div className="container">
      <div className="center-container">
        <div
          name="termly-embed"
          data-id="d03e6126-c743-41a7-93e5-369f53468a44"
          data-type="iframe"
        ></div>
      </div>

      {/* <CookieComponent /> */}
    </div>
  );
};

export default CookiePolicy;
