import BlogPageV2 from "../pages/BlogPageV2";
import ContactV2 from "../pages/ContactV2";
import CookiePolicy from "../pages/CookiePolicy";
import HomeV2 from "../pages/HomeV2";
import Media from "../pages/Media";
import Privacy from "../pages/Privacy";
import TermsAndConditions from "../pages/TermsAndConditions";
import WhatsNewV2 from "../pages/WhatsNewV2";
import WhoWeAreV2 from "../pages/WhoWeAreV2";

const allRoutes = [
  { path: "/", component: HomeV2 },
  { path: "/whoweare", component: WhoWeAreV2 },
  { path: "/whatsnew", component: WhatsNewV2 },
  { path: "/contact", component: ContactV2 },
  { path: "/media", component: Media },

  { path: "/termsandconditions", component: TermsAndConditions },
  { path: "/privacy", component: Privacy },
  { path: "/cookiepolicy", component: CookiePolicy },

  { path: "/article/:article", component: BlogPageV2 },
];

export { allRoutes };
