import ReactPaginate from "react-paginate";
import { BackIcon, NextIcon } from "../../utils/icons";
import { useEffect, useState } from "react";
import IsMobile from "../../utils/isMobile";

import "./pagination.css";

const Pagination = ({ onPageChange, pageCount, version }) => {
  const isMobile = IsMobile(991);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (e) => {
    setCurrentPage(e.selected); // Update current page state
    const element = document.querySelector(".news-card-text");
    const elementV2 = document.querySelector(".all-news");

    if (element || elementV2) {
      const elementPosition = elementV2
        ? elementV2.getBoundingClientRect().top + window.pageYOffset - 200
        : element.getBoundingClientRect().top + window.pageYOffset - 130;

      window.scrollTo({ top: elementPosition, behavior: "smooth" });
      onPageChange(e);
    }
  };

  return (
    <div className="position-relative">
      <ReactPaginate
        containerClassName={`pagination-container pagination ${version}`}
        pageClassName={`pagination-item ${version}`}
        activeClassName={`pagination-active ${version}`}
        breakLabel="..."
        nextLabel={
          <div className={`icons ${version}`}>
            {isMobile ? "" : "Next "}
            <NextIcon
              fill={`${
                version === "v2"
                  ? isMobile
                    ? "#667085"
                    : "#464655"
                  : "#94969c"
              }`}
            />
          </div>
        }
        onPageChange={handlePageChange}
        pageRangeDisplayed={isMobile ? 1 : 3}
        marginPagesDisplayed={isMobile ? 1 : 3}
        pageCount={pageCount}
        previousLabel={
          <div className={`icons ${version}`}>
            <BackIcon
              fill={`${
                version === "v2"
                  ? isMobile
                    ? "#667085"
                    : "#464655"
                  : "#94969c"
              }`}
            />
            {isMobile ? "" : " Previous"}
          </div>
        }
        hrefAllControls={"#"}
      />

      <div className="page-indicator d-lg-none d-flex">
        Page {currentPage + 1} of {pageCount}
      </div>
    </div>
  );
};

export default Pagination;
