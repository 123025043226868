import React from "react";
import "../Drcr/Drcr.css";

const DrcrV2 = () => {
  return (
    <div className="drcr-v2">
      <a
        target="_blank"
        href="https://www.otcmarkets.com/stock/DRCR/overview"
        className="drcr-link"
      >
        Swifty Global is now trading on the OTC Market under ticker DRCR
      </a>
    </div>
  );
};

export default DrcrV2;
