import React, { useEffect } from "react";

import CookieComponent from "../components/CookieComponent/CookieComponent";
import "../components/Styles/CookieTermsPrivacy.css";

const TermsAndConditions = () => {
  useEffect(() => {
    (function (d, s, id) {
      var js,
        tjs = d.getElementsByTagName(s)[0];
      // if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;

      js.src = "https://app.termly.io/embed-policy.min.js";

      tjs.parentNode.insertBefore(js, tjs);
    })(document, "script", "termly-jssdk");
  }, []);

  return (
    <div className="container">
      <div className="center-container">
        <div
          name="termly-embed"
          data-id="9ed61f4c-74cc-4f75-805c-4a24eadf8b6a"
          data-type="iframe"
        ></div>
      </div>

      {/* <CookieComponent /> */}
    </div>
  );
};

export default TermsAndConditions;
