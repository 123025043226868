import React, { useEffect } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { CloseIcon } from "../../utils/icons";
import IsMobile from "../../utils/isMobile";

import "./MapComponent.css";
const MapComponent = (props) => {
  const {
    longitude = -0.1278,
    latitude = 51.5074,
    zoom = 12,
    showMap,
    setShowMap,
  } = props;

  const googleMapsApiKey = "AIzaSyChIIvaxOGDLA70aJSxXuktdRJ5gEziOpk";
  const { isLoaded } = useLoadScript({ googleMapsApiKey: googleMapsApiKey });
  const isMobile = IsMobile(991);

  const mapContainerStyle = {
    width: "100%",
    height: isMobile ? "100%" : "500px",
    borderRadius: isMobile ? "0" : "16px",
    zIndex: "100",
  };

  const center = {
    lat: latitude,
    lng: longitude,
  };

  const markerIcon = {
    url: "https://img.icons8.com/color/48/null/marker--v1.png",
    scaledSize: { width: 30, height: 30 },
  };

  useEffect(() => {
    if (showMap && isMobile) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "";

    return () => {
      document.body.style.overflow = "";
    };
  }, [showMap, isMobile]);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="map-background">
      <CloseIcon onClick={() => setShowMap(false)} />
      <div className="map-container">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={zoom}
        >
          <Marker position={center} icon={markerIcon} />
        </GoogleMap>
      </div>
    </div>
  );
};

export default MapComponent;
