import React from "react";

import { LinkedInIcon, TwitterIcon, VectorIcon } from "../utils/icons";
import Button from "../components/Button/Button.component";

import "../components/Styles/WhoWeAreV2.css";

const WhoWeAreV2 = () => {
  const a = [
    "We revolutionize sports betting with Swifty Sports and Swifty Predictions, offering thousands of betting options, from pre-match bets to in-play micro-predictions.",
    "Swifty Sports provides over 1,000+ slots, table games, and immersive experiences, combining traditional casino thrills with the latest in gaming technology.",
    "Swifty Predictions offers a dynamic, fun way to engage with live sports, letting you bet on in-game moments as they happen with a simple swipe.",
  ];

  const team = [
    {
      name: "James Gibbons",
      position: "CEO",
      image: "../images/james-avatar.png",
      description:
        "James, with 15+ years in solutioning, strategy, design, and program management, sold a mobile voucher app at 23. Before founding Swifty Global, he led the Digital Visitor Experience for Expo 2020 Dubai.",
      twitterLink: "https://x.com/james_gibbons_",
      linkedInLink: "https://www.linkedin.com/in/james-gibbons-uk/",
    },
    {
      name: "Nick Link",
      position: "Chairman",
      image: "../images/niki-avatar.png",
      description:
        "Nick Link, CEO of ILUS International, is a serial entrepreneur with a talent for identifying promising businesses. He's helping James build a top-tier team of Directors to drive Swifty’s success.",
      twitterLink: "https://x.com/NicolasLink",
      linkedInLink: "https://www.linkedin.com/in/nicolas-nick-link-74978aa/",
    },
  ];

  const getInTouch = () => {
    window.location.href = "/contact";
  };

  return (
    <>
      <div className="container">
        <div className="who-we-are-v2">
          <div className="row justify-content-between">
            <span className="d-flex d-lg-none title">
              <p>Who </p>
              <p> We Are?</p>
            </span>
            <div className="col-7 col-md-6">
              <span className="d-lg-flex d-none title">
                <p>Who </p>
                <p> We Are?</p>
              </span>
              <p className="description">
                Meet the executive team at Swifty Global. The team has been
                growing and we now have a strong team of 30+ people that consist
                of designers, developers, traders, operations, customer service
                and compliance.
              </p>

              <div className="list d-none d-md-flex">
                {a.map((row, i) => {
                  return (
                    <div key={i} className="d-flex align-items-center gap-3">
                      <VectorIcon className="icon" />
                      <p className="m-0">{row}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-5 col-md-6 d-flex align-items-center">
              <img src="../images/sports.png" alt="Sports" className="w-100" />
            </div>
          </div>

          <div className="list d-flex d-md-none mt-4">
            {a.map((row, i) => {
              return (
                <div key={i} className="d-flex align-items-center gap-3">
                  <VectorIcon className="icon" />
                  <p className="m-0">{row}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="position-relative who-we">
        <div className="home-image">
          <img src="../images/behind-swifty-background.png" />
        </div>
        <div className="position-relative container our-team">
          <h1>OUR TEAM</h1>
          <p>
            We are Swifty Global, a global company lead by James Gibbons (CEO)
            and Nicolas Link (Chairman) and supported by our ever-growing team,
            our goal is to revolutionise the betting sector.
          </p>

          <div className="row justify-content-center gap-3 gap-md-0">
            {team.map((row, i) => {
              const {
                name,
                position,
                image,
                description,
                linkedInLink,
                twitterLink,
              } = row;
              return (
                <div className={`swifty-card-container col-12 col-md-6`}>
                  <div className="swifty-card info">
                    <img
                      src={image}
                      alt={`${name} - ${position}`}
                      className="ceo-chairman-img"
                    />

                    <h2>{name}</h2>
                    <span>{position}</span>
                    <span className="description">{description}</span>
                    <div className="d-flex justify-content-center gap-3">
                      <span
                        role="button"
                        className="m-0 w-auto"
                        onClick={() => window.open(twitterLink, "_blank")}
                      >
                        <TwitterIcon />
                      </span>
                      <span
                        role="button"
                        className="m-0 w-auto"
                        onClick={() => window.open(linkedInLink, "_blank")}
                      >
                        <LinkedInIcon />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="anything-to-ask">
          <p>Anything you would like to ask?</p>
          <Button
            text="Get in touch"
            className="general-button"
            onclickHandler={getInTouch}
          />
        </div>
      </div>
    </>
  );
};
export default WhoWeAreV2;
