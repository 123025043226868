import React from "react";
import Slider from "react-slick";

import "./Slider.css";
import IsMobile from "../../utils/isMobile";

const SliderComponents = (props) => {
  const { images = [] } = props;
  const isMobile = IsMobile(520);
  const isTablet = IsMobile(991);

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesToScroll: 1,
  };

  return (
    <div className={`slider ${isMobile || isTablet ? "" : "container"} `}>
      <Slider {...settings}>
        {images.map((row, index) => {
          return (
            <div className="slider-item" key={"c_" + index}>
              <img src={row} alt="" />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SliderComponents;
