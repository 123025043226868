import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { allRoutes } from "./routes/routes";
import HeaderV2 from "./components/Header/HeaderV2";
import DrcrV2 from "./components/Drcr/DrcrV2";
import FooterV2 from "./components/Footer/FooterV2";
import Page404 from "./pages/404Page";
import ScrollToTop from "./components/ScrollOnTop/ScrollOnTop";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <div>
      <ScrollToTop />
      <div className="sticky-top">
        <DrcrV2 />
        <HeaderV2 />
      </div>

      <Routes>
        {allRoutes.map((row, i) => {
          const { path, component: Component } = row;
          return <Route key={i} path={path} element={<Component />} />;
        })}

        {/* Fallback route for 404 */}
        <Route path="/page-404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/page-404" replace />} />
      </Routes>

      {window.location.pathname.includes("page-404") ? (
        ""
      ) : (
        <div className="footer-V2">
          <FooterV2 />
        </div>
      )}
    </div>
  );
}

export default App;
