import React, { useEffect, useRef, useState } from "react";
import { CloseIcon } from "../../utils/icons";
import ReactPlayer from "react-player";
import useOutsideClick from "../../utils/useOutsideClick";

import "./VideoBackground.css";

const VideoBackground = ({ videoSrc, isVideoOpen, setIsVideoOpen, page }) => {
  const modalRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (isVideoOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "";

    return () => {
      document.body.style.overflow = "";
    };
  }, [isVideoOpen]);

  useOutsideClick(modalRef, () => setIsVideoOpen(false));

  if (!isVideoOpen) return null;

  return (
    <div className={`video-container ${page ? page : ""}`}>
      <CloseIcon onClick={() => setIsVideoOpen(false)} />
      <div className="video-item" ref={modalRef}>
        <ReactPlayer
          url={videoSrc}
          width="100%"
          height="100%"
          controls
          playing
        />
      </div>
    </div>
  );
};

export default VideoBackground;
