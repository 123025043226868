import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import "../components/Styles/CookieTermsPrivacy.css";
import CookieComponent from "../components/CookieComponent/CookieComponent";
import Header from "../components/Header/Header";

const Privacy = () => {
  useEffect(() => {
    (function (d, s, id) {
      var js,
        tjs = d.getElementsByTagName(s)[0];

      // if (d.getElementById(id)) return;

      js = d.createElement(s);
      js.id = id;

      js.src = "https://app.termly.io/embed-policy.min.js";

      tjs.parentNode.insertBefore(js, tjs);
    })(document, "script", "termly-jssdk");
  }, []);

  return (
    <div className="container">
      <div className="center-container">
        <div
          name="termly-embed"
          data-id="e83e6456-2a0a-4141-926d-56e9b70dd263"
          data-type="iframe"
        ></div>
      </div>

      {/* <CookieComponent /> */}
    </div>
  );
};

export default Privacy;
