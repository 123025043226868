import React, { useEffect, useState } from "react";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import Input from "../components/Input/Input";
import { DoneIcon, LocationIcon, StarIcon } from "../utils/icons";
import MapComponent from "../components/Map/MapComponent";
import Button from "../components/Button/Button.component";
import IsMobile from "../utils/isMobile";
import Modal from "../components/Modal/Modal";

import "react-toastify/dist/ReactToastify.css";
import "../components/Styles/ContactV2.css";

const ContactV2 = () => {
  const isMobile = IsMobile(991);

  const [position, setPosition] = useState({ lat: 51.5074, lon: 0.1278 });
  const [showSubmitMessage, setShowSubmitMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [data, setData] = useState({
    email: "",
    name: "",
    message: "",
    subject: "",
  });

  const toastError = () => {
    toast.error("Please fill in all of the fields!", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const locations = [
    {
      name: "NEW YORK",
      email: "hello@swifty.global",
      phone: "+1 (917) 970-1433",
      locations: "26 Broadway, Suite 934, New York, NY 10004, USA",
      latitude: 40.7074,
      longitude: -74.012,
    },
    {
      name: "LONDON",
      email: "hello@swifty.global",
      phone: "+44 (0) 204 542 4517",
      locations:
        "71-75, Shelton Street, Covent Garden, London, WC2H 9JQ,United Kingdom",
      latitude: 51.5149,
      longitude: 0.1245,
    },
    {
      name: "DUBAI",
      email: "hello@swifty.global",
      phone: "+971 (0) 4 512 4070",
      locations: "Office 1105, Marina Plaza, Marina, Dubai, UAE",
      latitude: 25.078,
      longitude: 55.1413,
    },
  ];

  const submittedData = (e) => {
    e.preventDefault();
    if (!data.email || !data.message || !data.name || !data.subject) {
      toastError();
    } else {
      setIsLoading(true);
      submit();
      setData({ email: "", name: "", message: "", subject: "" });
    }
  };

  const submit = () => {
    const url = "https://prod.swifty-api.com/main/v1/feedback_website";
    let body = {
      email: data.email,
      name: data.name,
      message: data.message,
      subject: data.subject,
    };

    axios.put(url, body).then(() => {
      setShowSubmitMessage(true);
      setIsLoading(false);
    });
  };

  const handle = (e, field) => {
    const value = e.target.value;
    setData({ ...data, [field]: value });
  };

  const getLocation = (data) => {
    const { latitude, longitude } = data;
    setPosition({ lat: latitude, lon: longitude });

    if (isMobile) setShowMap(true);
  };

  useEffect(() => {
    if (isMobile) setShowMap(false);
    else setShowMap(true);
  }, [isMobile]);

  return (
    <>
      <div className="footer-V2">
        <div className="container">
          <div className="row contact-us justify-content-between">
            <div className="col-12 col-lg-6">
              <h1>Contact Us</h1>
              <p>
                Need help, support or just want to drop us a message?
                <br /> Fill out the form or write to us on one of the channels
                listed below.
              </p>

              <form onSubmit={(e) => submittedData(e)}>
                <Input
                  label="Full Name"
                  placeholder="Full Name"
                  value={data?.name}
                  onChange={(e) => handle(e, "name")}
                  inputContainer="contact-input-container"
                />
                <Input
                  label="Email Address"
                  value={data?.email}
                  onChange={(e) => handle(e, "email")}
                  inputContainer="contact-input-container"
                />
                <div className="contact-input-container">
                  <span>Select a topic</span>
                  <select
                    onChange={(e) => handle(e, "subject")}
                    id="subject"
                    value={data.subject}
                    name="topics"
                    className="contact-buttons select"
                    placeholder="Select a topic"
                  >
                    <option value>Select a topic</option>
                    <option value="Topic 1">General Feedback</option>
                    <option value="Topic 2">Bug or issue</option>
                    <option value="Topic 3">Marketing or media</option>
                    <option value="Topic 4">Complaint</option>
                  </select>
                </div>

                <div className="contact-input-container">
                  <span>Message</span>
                  <textarea
                    cols="40"
                    rows="4"
                    onChange={(e) => handle(e, "message")}
                    id="message"
                    value={data.message}
                    type="text"
                    className="contact-buttons"
                    placeholder="Leave a message"
                  />
                </div>

                <div>
                  <Button
                    text="Send message"
                    className="contact-us-button"
                    onclickHandler={submittedData}
                    isLoading={isLoading}
                  />
                  <ToastContainer
                    position="top-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </div>
              </form>

              {showSubmitMessage && (
                <Modal
                  isModalOpen={showSubmitMessage}
                  setIsModalOpen={setShowSubmitMessage}
                >
                  <div className="contact-modal">
                    <DoneIcon />
                    <h1>Thank you</h1>
                    <p>
                      Your message has been successfully sent! <br />
                      One of our team members will be in touch with you soon.
                    </p>

                    <Button
                      text="Return to Home"
                      className="contact-us-button"
                      onclickHandler={() => (window.location.href = "/")}
                    />
                  </div>
                </Modal>
              )}
            </div>
            <div className="col-12 col-lg-5 d-none d-lg-flex align-items-center">
              <img
                src="../images/person-with-laptop.png"
                alt="Young entrepreneur with laptop"
                className="who-we-are-img"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="our-locations">
          <div className="d-none d-lg-block">
            <h1>Our Locations</h1>
            <p>Come visit our friendly team at one of our offices.</p>
          </div>

          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-lg-3">
              {locations.map((row, i) => {
                const { name, email, phone, locations } = row;
                return (
                  <div key={i} className="locations-container">
                    <div className="locations-title">
                      <LocationIcon />
                      <h2>{name}</h2>
                    </div>
                    <div className="info">
                      <span>Email: {email}</span>
                      <span>Phone: {phone}</span>
                    </div>

                    <span className="location" onClick={() => getLocation(row)}>
                      {locations}
                    </span>
                  </div>
                );
              })}
            </div>

            {showMap && (
              <div className="col-12 col-lg-8">
                <MapComponent
                  latitude={position.lat}
                  longitude={position.lon}
                  showMap={showMap}
                  setShowMap={setShowMap}
                  zoom={isMobile ? 14 : 12}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactV2;
