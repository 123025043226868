import React from "react";

import Button from "../components/Button/Button.component";

import "../components/Styles/404Page.css";

const Page404 = () => {
  return (
    <div className={`page-404-container`}>
      <div className="container page-404-image">
        <img src={"../images/page-404.png"} alt="" />
      </div>

      <div className="page-404-items">
        <h1>Page not found</h1>
        <p>Oops! We couldn't find that page.</p>
        <Button
          text="Return to home"
          className="contact-us-button"
          onclickHandler={() => (window.location.href = "/")}
        />
      </div>
    </div>
  );
};

export default Page404;
