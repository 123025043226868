import React, { useEffect, useState } from "react";
import { PlayIcon } from "../utils/icons";
import axios from "axios";
import SpinnerComponent from "../components/Spinner/SpinnerComponent";
import VideoBackground from "../components/VideoBackground/VideoBackground";
import IsMobile from "../utils/isMobile";

import "../components/Styles/Media.css";

const Media = () => {
  const isMobile = IsMobile(575);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bannerData, setBannerData] = useState([]);
  const [playVideo, setPlayVideo] = useState("");

  // Function to check if an image exists
  const checkImageExists = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  // Wrapper to fetch a valid image or fallback to a placeholder
  const getImage = async (image) => {
    const exists = await checkImageExists(image);
    return exists ? image : "../images/swifty-placeholder.png";
  };

  const getMedia = async () => {
    setIsLoading(true);
    const url =
      "https://18z0qwmdu8.execute-api.eu-west-1.amazonaws.com/main/v1/data";

    const payload = {
      collection: "media",
      sort: { date: -1 },
    };

    try {
      const res = await axios.post(url, payload);
      const { data } = res;
      let newData = [];
      let newBannerData = [];
      const featured = ["1", "2", "3"];

      for (const row of data.documents) {
        row.image = await getImage(row.image); // Verify and assign the valid image
        if (featured.includes(row.featured)) newBannerData.push(row);
        else newData.push(row);
      }

      setBannerData(newBannerData);
      setData(newData);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMedia();
  }, []);

  return (
    <>
      <div className="media-title-container">
        <div className={`container media-title`}>
          <h1>
            A hub for all the latest videos and technological advancements from
            Swifty
          </h1>
          <p>
            Explore Swifty's cutting-edge advancements in Sports, Technology,
            and Predictions. Discover how Swifty is revolutionizing sports
            betting and interactive gaming through innovative solutions and
            immersive experiences.
          </p>
        </div>
      </div>
      <div className={`${isMobile ? "" : "container"} overflow-x-hidden`}>
        <div className="media-container">
          <div className="madia-banner-container">
            {bannerData.map((row, i) => {
              const { _id, title, video, image } = row;

              return (
                <div key={i} className={`media-banner`}>
                  <div className="image-container">
                    <img src={image} alt={title} />

                    <div className="icon">
                      <PlayIcon onClick={() => setPlayVideo(_id)} />
                    </div>
                  </div>

                  <div className="title">
                    <p>{`${i + 1} /`}</p>
                    <p className="m-0">{title}</p>
                  </div>

                  {playVideo === _id && (
                    <VideoBackground
                      videoSrc={video}
                      isVideoOpen={playVideo}
                      setIsVideoOpen={setPlayVideo}
                      page="media"
                    />
                  )}
                </div>
              );
            })}
          </div>

          <div className="madia-video-container">
            <h3 className="d-none d-lg-block">Media</h3>

            <div className="row">
              {isLoading ? (
                <div className="text-center">
                  <SpinnerComponent size="md" />
                </div>
              ) : (
                data.map((row, i) => {
                  const { _id, title, video, image } = row;

                  return (
                    <div
                      key={i}
                      className={`col-12 col-lg-4 col-md-6 madia-video`}
                    >
                      <div className="image-container">
                        <img src={image} alt={title} />

                        <div className="icon">
                          <PlayIcon onClick={() => setPlayVideo(_id)} />
                        </div>
                      </div>

                      <span>{title}</span>

                      {playVideo === _id && (
                        <VideoBackground
                          videoSrc={video}
                          isVideoOpen={playVideo}
                          setIsVideoOpen={setPlayVideo}
                          page="media"
                        />
                      )}
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Media;
