import SpinnerComponent from "../Spinner/SpinnerComponent";

const Button = (props) => {
  return (
    <button
      type={props.type}
      onClick={props.onclickHandler}
      className={props.className}
      {...props.attributes}
    >
      {props.isLoading ? (
        <SpinnerComponent size={props.size || "md"} />
      ) : (
        props.children || props.text
      )}
    </button>
  );
};

export default Button;
