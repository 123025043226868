import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { NextIcon } from "../../utils/icons";

import "../Styles/Home.css";
import "./Slider.css";

// export default class HomeSlider extends Component {

const HomeSlider = ({ cards, children }) => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    slidesToScroll: 1,
  };

  return (
    <div className="home-slider">
      <Slider {...settings}>
        {children}
        {/* {cards.map((row, index) => {
          const { image, title, short_description, slug } = row;
          return (
            <div key={index} className={`home-slider-item`}>
              {image.length > 0 ? (
                <img src={image} className="image" alt={title} />
              ) : (
                <img
                  src="../images/swifty-placeholder.png"
                  className="image"
                  alt="Swifty Placeholder Image"
                />
              )}

              <div className="content">
                <span className="">
                  <p className="title">{title}</p>

                  <Link to={`/article/${slug}`} className="">
                    <NextIcon fill="#4086FA" />
                  </Link>
                </span>
                <p className="short-description">{short_description}</p>
              </div>
            </div>
          );
        })} */}
      </Slider>
    </div>
  );
};

export default HomeSlider;
