import { useEffect, useState } from "react";
import {
  BehindSwiftyIcon,
  PlayVideoIcon,
  SwiftyPlatformIcon,
  SwiftyPredictionsIcon,
  SwiftySportsIcon,
} from "../../utils/icons";
import VideoBackground from "../VideoBackground/VideoBackground";

import "./BehindSwifty.css";

const BehindSwifty = () => {
  const [playVideo, setPlayVideo] = useState("");

  const cards = [
    {
      title: "Swifty Sports & Casino",
      description: `Swifty Sports & Casino is an online gambling company that allows  
      you to browse and place pre-match and in-play bets on all your favorite sports. Bet on horses, 
      football, baseball, boxing and  more. Enjoy competitive odds and a seamless betting experience, all in one place.`,
      visit_us_link: "https://www.swiftysports.ie/en",
      play_video_link: "https://youtu.be/6xiQrh7YHYI?si=2LoG5MkLgcAHcYPW",
      className: "sports",
      icon: <SwiftySportsIcon />,
    },
    {
      title: "Swifty Platform",
      description: `Our platform offers a global betting solution for established operators and newcomers 
      looking to disrupt the industry. Our Sportsbook provides comprehensive pre-match and live betting across various sports markets. 
      Enjoy a diverse and engaging gaming experience with our wide range of games, from slots to table games.`,
      visit_us_link: "https://www.swiftytechnologies.com",
      play_video_link: "https://youtu.be/ZoXuOwKg470?si=3tMh29z0G0RTHzN-",
      className: "platform",
      icon: <SwiftyPlatformIcon />,
    },
    {
      title: "Swifty Predictions",
      description: `Swifty Predictions lets you make live predictions during your
      favorite sports events. Whether it's a goal, set, or game-winning
      play, bet on the next big moment as it happens. Swipe right to
      predict, left to skip, and enjoy in-game micro-betting at your pace.`,
      visit_us_link: "https://www.swiftypredictions.co.uk",
      play_video_link: "https://youtu.be/uok8W8xAynQ?si=5IyoCEcxYBwlw7Op",
      className: "predictions",
      icon: <SwiftyPredictionsIcon />,
    },
  ];

  return (
    <div className="position-relative">
      <div className="home-image">
        <img src="../images/behind-swifty-background.png" />
      </div>

      <div className="behind-swifty container ">
        <h1 className="text-center text-uppercase m-0">Behind Swifty</h1>
        <div className="behind-swifty-wrapper position-relative">
          <div className="text-center mb-md-5 mb-0 position-relative behind-swifty-icon">
            <BehindSwiftyIcon />
            <div className="line"></div>
          </div>

          <div className="row justify-content-center">
            {cards.map((row, i) => {
              const {
                title,
                description,
                visit_us_link,
                play_video_link,
                className,
                icon,
              } = row;

              return (
                <div
                  key={i}
                  className={`swifty-card-container col-12 col-lg-4 ${className}`}
                >
                  <span className="card-icon">{icon}</span>

                  <div className="swifty-card">
                    <span>{title}</span>
                    <p>{description}</p>
                    <div className="d-flex gap-3">
                      <a
                        href={visit_us_link}
                        target="https://www.swiftysports.ie"
                      >
                        Visit Us
                      </a>
                      <a onClick={() => setPlayVideo(className)}>
                        Play Video <PlayVideoIcon />
                      </a>

                      {playVideo === className && (
                        <VideoBackground
                          videoSrc={play_video_link}
                          isVideoOpen={playVideo}
                          setIsVideoOpen={setPlayVideo}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BehindSwifty;
