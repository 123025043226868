import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Button/Button.component";

import { NextIcon } from "../../utils/icons";
import { Skeleton } from "../Skeleton/Skeleton";
import IsMobile from "../../utils/isMobile";

import "./LatestNewsV2.css";

const LatestNewsV2 = ({ page, cards = [], latestNews = [], isLoading }) => {
  const navigate = useNavigate();
  const isMobile = IsMobile();

  const skeletonItems = Array.from({ length: isMobile ? 4 : 9 });
  const latestNewsSkeletonItems = Array.from({
    length: page === "home" ? 3 : 2,
  });

  const [loading, setLoading] = useState(false);

  const goToNews = (slug) => {
    navigate(`/article/${slug}`);
  };

  const getDate = (date) => {
    return new Date(date).toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <div className="latest-news-v2 container">
      <div className="latest-news-header">
        <p>Latest News</p>

        {page === "home" ? (
          <Button
            text="View All"
            className="general-button view-all-button"
            onclickHandler={() => navigate("/whatsnew")}
          />
        ) : (
          ""
        )}
      </div>

      <div className="row latest-news-card">
        {!latestNews.length
          ? latestNewsSkeletonItems.map((row, i) => {
              return (
                <div
                  key={i}
                  className={`col-12 item ${
                    page === "home" ? "col-md-4" : "col-md-6"
                  }`}
                >
                  <Skeleton height={250} width="100%" />

                  <div className="content">
                    <Skeleton
                      sx={{ marginBottom: "5px" }}
                      height={30}
                      width="100%"
                    />
                    <Skeleton
                      sx={{ marginBottom: "5px" }}
                      height={30}
                      width="100%"
                    />
                    <Skeleton
                      sx={{ marginBottom: "5px" }}
                      height={30}
                      width="100%"
                    />
                  </div>
                </div>
              );
            })
          : latestNews?.map((row, index) => {
              const { image, title, date, short_description, slug } = row;
              return (
                <div
                  key={index}
                  className={`col-12 item ${
                    page === "home" ? "col-md-4" : "col-md-6"
                  }`}
                >
                  {image.length > 0 ? (
                    <img
                      src={image}
                      className="image"
                      alt={title}
                      onClick={() => goToNews(slug)}
                    />
                  ) : (
                    <img
                      src="../images/swifty-placeholder.png"
                      className="image"
                      alt="Swifty Placeholder Image"
                      onClick={() => goToNews(slug)}
                    />
                  )}

                  <div className="content">
                    <p className="date">{getDate(date)}</p>
                    <span role="button" onClick={() => goToNews(slug)}>
                      <p className="title">{title}</p>

                      <Link to={`/article/${slug}`}>
                        <NextIcon fill="#4086FA" />
                      </Link>
                    </span>
                    {page !== "home" && (
                      <p className="short-description">{short_description}</p>
                    )}
                  </div>
                </div>
              );
            })}
      </div>

      {isLoading ? (
        <div className="row latest-news-card">
          <div className="latest-news-header all-news">
            <p>All News</p>
          </div>
          {skeletonItems.map((row, i) => {
            return (
              <div key={i} className="col-12 col-lg-4 item">
                <Skeleton height={150} width="100%" />

                <div className="content">
                  <Skeleton
                    sx={{ marginBottom: "5px" }}
                    height={30}
                    width="100%"
                  />
                  <Skeleton
                    sx={{ marginBottom: "5px" }}
                    height={30}
                    width="100%"
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        cards?.length > 0 && (
          <div>
            <div className="latest-news-header all-news">
              <p>All News</p>
            </div>
            <div className="row latest-news-card">
              {cards.map((row, index) => {
                const { image, title, date, short_description, slug } = row;
                const newImage =
                  image.length > 0 ? image : "../images/swifty-placeholder.png";
                return (
                  <div key={index} className="col-12 col-lg-4 col-md-6 item">
                    <img
                      src={newImage}
                      className="image"
                      alt={title}
                      onClick={() => goToNews(slug)}
                    />

                    <div className="content">
                      <p className="date">{getDate(date)}</p>
                      <span role="button" onClick={() => goToNews(slug)}>
                        <p className="title">{title}</p>

                        <Link to={`/article/${slug}`}>
                          <NextIcon fill="#4086FA" />
                        </Link>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )
      )}
    </div>
  );
};
export default LatestNewsV2;
