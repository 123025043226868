import React, { useEffect, useRef } from "react";
import { CloseIcon } from "../../utils/icons";
import useOutsideClick from "../../utils/useOutsideClick";

import "./Modal.css";

const Modal = (props) => {
  const { isModalOpen, children, setIsModalOpen } = props;

  const modalRef = useRef(null);

  useEffect(() => {
    if (isModalOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "";

    return () => {
      document.body.style.overflow = "";
    };
  }, [isModalOpen]);

  useOutsideClick(modalRef, () => setIsModalOpen(false));

  return (
    <div className="modal-overlay">
      <div className="modal-container" ref={modalRef}>
        <div className="text-end">
          <CloseIcon fill="#232A38" onClick={() => setIsModalOpen(false)} />
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
