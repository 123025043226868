import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "../components/Pagination/pagination";
import LatestNewsV2 from "../components/LatestNews/LatestNewsV2";
import Button from "../components/Button/Button.component";
import { Skeleton } from "../components/Skeleton/Skeleton";
import IsMobile from "../utils/isMobile";

import "../components/Styles/WhatsNewV2.css";

const WhatsNewV2 = () => {
  const navigate = useNavigate();
  const isMobile = IsMobile(522);

  const [cards, setCards] = useState([]);
  const [singleCard, setSingleCard] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(3);

  const [check, setCheck] = useState(true);

  const loadMore = (e) => {
    const selectedPage = e.selected;

    let skip = 0;
    if (selectedPage === 1) skip = 12;
    else skip = 9 * selectedPage + 3;

    getDocs(9, skip);
  };

  const getDocs = (limit, skip) => {
    setIsLoading(true);

    const url =
      "https://18z0qwmdu8.execute-api.eu-west-1.amazonaws.com/main/v1/data";

    const payload = {
      collection: "articles",
      sort: { date: -1 },
      skip: skip ? skip : 0,
      limit: limit ? limit : 12,
    };

    axios
      .post(url, payload)
      .then((res) => {
        if (check === true) {
          let resp = res.data.documents;
          setSingleCard(resp[0]); //first object
          const latestNews = resp.slice(1, 3);
          setLatestNews(latestNews);
          resp = resp.slice(3);
          setCards(resp); //second object onwards
          setCheck(false);
        } else {
          let resp = res.data.documents;
          if (skip === 3) resp = resp.slice(3);
          setCards(resp);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPageCount = () => {
    const url =
      "https://18z0qwmdu8.execute-api.eu-west-1.amazonaws.com/main/v1/data";
    const payload = { collection: "articles", sort: { date: -1 } };

    axios.post(url, payload).then((res) => {
      let resp = res.data.documents;
      resp = resp.slice(3);
      setPageCount(Math.ceil(resp.length / 9));
    });
  };

  useEffect(() => {
    if (!cards.length) {
      setIsLoading(true);
      getDocs();
    }
  }, []);

  useEffect(() => {
    if (!latestNews.length) getPageCount();
  }, []);

  const d = singleCard?.date
    ? new Date(singleCard?.date).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : "";

  return (
    <div className="whats-new-v2">
      <div className="footer-V2">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-7">
              <p className="date">
                {d ? (
                  d
                ) : (
                  <Skeleton
                    height={30}
                    width="100%"
                    className={"skeleton-dark"}
                  />
                )}
              </p>

              <p className="title">
                {singleCard?.title ? (
                  singleCard?.title
                ) : (
                  <Skeleton
                    height={200}
                    width="100%"
                    className={"skeleton-dark"}
                  />
                )}
              </p>
              <p className={`description ${isMobile ? "d-none" : ""}`}>
                {singleCard?.short_description ? (
                  singleCard?.short_description
                ) : (
                  <Skeleton
                    height={150}
                    width="100%"
                    className={"skeleton-dark"}
                  />
                )}
              </p>

              {singleCard?.title ? (
                <Button
                  text="Read More"
                  className="general-button d-none d-md-block"
                  onclickHandler={() =>
                    navigate(`/article/${singleCard?.slug}`)
                  }
                />
              ) : null}
            </div>
            <div className="col-5 text-end d-flex">
              <img
                src="../images/swifty-wallet.png"
                alt="Swifty Platform"
                className="who-we-are-img"
              />
            </div>

            <p className={`description ${!isMobile ? "d-none" : ""}`}>
              {singleCard?.short_description ? (
                singleCard?.short_description
              ) : (
                <Skeleton
                  height={150}
                  width="100%"
                  className={"skeleton-dark"}
                />
              )}
            </p>

            <div className="d-block d-md-none container">
              {singleCard?.title ? (
                <Button
                  text="Read More"
                  className="general-button w-100"
                  onclickHandler={() =>
                    navigate(`/article/${singleCard?.slug}`)
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <LatestNewsV2
        page={"whatsNew"}
        cards={cards}
        latestNews={latestNews}
        isLoading={isLoading}
      />

      <div className="container">
        <Pagination
          onPageChange={loadMore}
          pageCount={pageCount}
          version={"v2"}
        />
      </div>
    </div>
  );
};

export default WhatsNewV2;
